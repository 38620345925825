.App {
  text-align: center;
  position: relative;
}

.App-logo {
  pointer-events: none;
  position: absolute;
  top:10px;
  left: 10px;
  height:3rem;
  width: auto;
}