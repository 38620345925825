.matcha {
  background-color: #485731;
}
.taro {
  background-color: #634C91;
}
.classic {
  background-color: #3C3333;
}
.caramel {
  background-color: #875020;
}
.blossom {
  background-color: #C44191;
}
.ivorySilk {
  background-color: #D2BBA2;
}
.sparkle {
  background-color: #D73663;
}
.chicken {
  background-color: #C0392B;
}
.berrymerry {
  background: #c23119;
  background: linear-gradient(90deg, #c23119 22%, #485731 54%);
}
.thai {
  background-color: #CA8346;
}
.kokocaphe {
  background-color: #756E30;
}
.jamong {
  background-color: #FF4D4D;
}
.general {
  background-color: #222222;
}
.generalItem {
  background-color: #C1A97E;
}
@keyframes rotateInCircle {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(90);
  }
  66% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shakeItUp {
  33% {
    transform: rotate(-15deg);
  }
  66% {
    transform: rotate(35deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInHeaderBackground {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: #000000;
  }
}
@keyframes fadeOutHeaderBackground {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}
.wastageStatsContainer {
  margin: auto;
  background-color: #91BCB9;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1024px;
  width: 100%;
}
.wastageStatsContainer .totalField {
  font-weight: bold;
}
.wastageStatsContainer .infoDiv {
  width: 100%;
  text-align: center;
  font-size: 1em;
  background-color: #F3F1E4;
  border-radius: 1.2em;
  color: #47908A;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wastageStatsContainer .infoDiv .revenueSplits {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .infoDiv .revenueSplits {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
.wastageStatsContainer .infoDiv .revenueSplits div p {
  text-align: left;
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .infoDiv .revenueSplits div p {
    text-align: center;
  }
}
.wastageStatsContainer .infoDiv .branchSplits {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .infoDiv .branchSplits {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
.wastageStatsContainer .infoDiv .branchSplits div {
  border-radius: 6px;
  background-color: #47908A;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .infoDiv .branchSplits div {
    width: 90%;
    margin-bottom: 8px;
  }
}
.wastageStatsContainer .infoDiv .branchSplits div p {
  color: #F3F1E4;
  text-align: center;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .infoDiv .branchSplits div p {
    text-align: center;
  }
}
.wastageStatsContainer .infoDiv .customerName {
  font-size: 1.4em;
  text-align: center;
  font-weight: bold;
}
.wastageStatsContainer .infoDiv p span {
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .infoDiv {
    width: 95%;
  }
}
.wastageStatsContainer .sortIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}
.wastageStatsContainer .dateContainer {
  width: 100%;
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin-bottom: 20px;
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .dateContainer {
    flex-direction: column;
  }
}
.wastageStatsContainer .dateContainer .dateInput {
  appearance: none;
  background-color: #FFF;
  outline: none;
  border: none;
  border-color: #47908A;
  border-radius: 6px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  margin: 10px;
  text-align: center;
  max-width: 250px;
  font-family: 'Lexend Exa', sans-serif;
  font-weight: bold;
  color: #47908A;
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .dateContainer .dateInput {
    margin: auto;
    margin-bottom: 1em;
  }
}
.wastageStatsContainer .backBtn {
  font-size: 1.3em;
  color: #F3F1E4;
  background: #222222;
  border-radius: 1.2em;
  padding: 1.4em;
  text-transform: uppercase;
  font-weight: bold;
  margin: auto;
  margin-top: 0.5em;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.wastageStatsContainer .backBtn:hover {
  opacity: 0.5;
  cursor: pointer;
}
.wastageStatsContainer .backBtn .backIcon {
  font-size: 1.5em;
  position: absolute;
  left: 1em;
}
.wastageStatsContainer .wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
}
.wastageStatsContainer .wrapper .statsTable {
  margin: auto;
  margin-top: 0.5em;
  width: 90%;
}
@media screen and (max-width: 640px) {
  .wastageStatsContainer .wrapper .statsTable {
    margin-top: 8px;
    width: 80%;
    max-width: unset;
  }
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .wrapper {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.wastageStatsContainer .infoDiv {
  text-align: center;
  font-size: 1em;
  background-color: #F3F1E4;
  border-radius: 1.2em;
  padding: 20px;
  color: #47908A;
  margin-top: 0.5em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wastageStatsContainer .infoDiv .numberBubbles {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 1100px) {
  .wastageStatsContainer .infoDiv .numberBubbles {
    flex-direction: column;
  }
}
.wastageStatsContainer .infoDiv .numberBubbles .numberBubble {
  border-radius: 6px;
  color: #47908A;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 220px;
  font-weight: 800;
  font-size: 12px;
}
.wastageStatsContainer .infoDiv .numberBubbles .numberBubble .numberCircle {
  color: #000;
  background-color: #FFF;
  border-radius: 6px;
  height: 1.2em;
  padding: 0.8em;
  font-size: 12px;
  min-width: 120px;
  margin-top: 20px;
}
@media screen and (max-width: 1100px) {
  .wastageStatsContainer .infoDiv .numberBubbles .numberBubble {
    max-width: unset;
    width: 100%;
    max-width: 80%;
  }
}
.wastageStatsContainer .infoDiv .customerName {
  font-size: 1.4em;
  text-align: center;
  font-weight: bold;
}
.wastageStatsContainer .infoDiv .countsTable {
  border-collapse: collapse;
  max-width: 90%;
  margin: auto;
  overflow-x: auto;
}
.wastageStatsContainer .infoDiv .countsTable .unknownName {
  color: #e67e22;
  font-weight: bold;
}
.wastageStatsContainer .infoDiv .countsTable .hideBorder {
  border: none !important;
}
.wastageStatsContainer .infoDiv .countsTable td {
  border: solid 2px #47908A;
  padding: 5px;
}
@media screen and (max-width: 640px) {
  .wastageStatsContainer .infoDiv .countsTable {
    zoom: 0.6;
  }
}
.wastageStatsContainer .infoDiv p span {
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .infoDiv {
    width: 80%;
    margin-right: 0;
  }
}
.wastageStatsContainer .instructionLabel {
  padding: 5px;
  border-radius: 5px;
  background-color: #48dbfb;
  color: #222222;
  text-transform: uppercase;
  width: auto;
  text-align: center;
  margin-top: 0.5em;
}
.wastageStatsContainer .orderCards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  padding-top: 12em;
}
.wastageStatsContainer .searchFilterContainer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #222222;
}
.wastageStatsContainer .actionIcon {
  color: #F3F1E4;
}
.wastageStatsContainer .actionIcon:hover {
  opacity: 0.7;
  cursor: pointer;
}
.wastageStatsContainer .logoutIcon {
  padding-left: 10px;
  padding-right: 10px;
}
.wastageStatsContainer .filterContainer {
  display: flex;
  flex-direction: row;
  width: 100vw;
  margin: auto;
  padding-top: 1.2em;
  justify-content: space-between;
  background-color: #222222;
  margin-bottom: 1.5em;
}
@media screen and (max-width: 800px) {
  .wastageStatsContainer .filterContainer {
    justify-content: space-between;
  }
}
.wastageStatsContainer .filterContainer .badge {
  min-width: 100px;
  opacity: 0.6;
  margin-right: 1em;
}
@media screen and (max-width: 480px) {
  .wastageStatsContainer .filterContainer .badge {
    min-width: 80px;
  }
}
.wastageStatsContainer .filterContainer .badge:nth-child(1) {
  margin-left: 1em;
}
.wastageStatsContainer .filterContainer .badge:hover {
  opacity: 1;
  cursor: pointer;
}
.wastageStatsContainer .filterContainer .activeBadge {
  opacity: 1;
}
.wastageStatsContainer .filterContainer .activeBadge:hover {
  opacity: 0.6;
  cursor: pointer;
}
.wastageStatsContainer .badge {
  cursor: pointer;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  color: #F3F1E4;
  padding: 0.5em;
}
.wastageStatsContainer .badge:hover {
  opacity: 0.6;
}
.wastageStatsContainer .sourceTitle {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2em;
}
.wastageStatsContainer .orderCount {
  font-size: 1.2em;
}
.wastageStatsContainer .money {
  color: #0b5b2c;
  font-size: 1em;
}
.wastageStatsContainer .moneyLarge {
  color: #0b5b2c;
  font-size: 2.4em;
  font-weight: 500;
}
.wastageStatsContainer .moneyBadge {
  background-color: #0b5b2c;
}
.wastageStatsContainer .activeStatusBadge {
  background-color: #f0932b;
}
.wastageStatsContainer .completedBadge {
  background-color: #6ab04c;
}
.wastageStatsContainer .rejectedBadge {
  background-color: #eb4d4b;
}
.wastageStatsContainer .deliveryBadge {
  background-color: #3498db;
}
.wastageStatsContainer .pickupBadge {
  background-color: #AC3B61;
}
.wastageStatsContainer .takeawayBadge {
  background-color: #e67e22;
}
.wastageStatsContainer .searchBarContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #222222;
  margin: auto;
  padding-top: 1.2em;
}
@media screen and (max-width: 600px) {
  .wastageStatsContainer .searchBarContainer {
    flex-direction: column;
  }
  .wastageStatsContainer .searchBarContainer .actionContainer {
    padding-top: 10px;
  }
}
.wastageStatsContainer .searchBarContainer .searchBar {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  color: #F3F1E4;
  height: 1.2em;
  font-size: 1.1em;
  appearance: none;
  border: none;
  outline: none;
  font-weight: bold;
  margin: auto;
  width: 80%;
}
